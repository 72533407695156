
$(document).ready(function () {
    $(function () {
        console.log('qsdf')
        var mixer = mixitup('.container', {
            load: {
                filter: '.now',
            },
        });


        var buttonOpenPopin = $('.js-open-desc')
        var butonClose = $('.js-close-popin')

        buttonOpenPopin.on('click', function (e) {
            e.preventDefault();
            var self = $(this)

            self.find('.overlay').fadeIn()
            $('body').css({'overflow': 'hidden'})
        })

        butonClose.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var self = $(this)


            self.parent().parent().fadeOut()
            $('body').css({'overflow': 'auto'})

        })
    });
});